import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import Button from 'components/ui/Button';
import ButtonCircle from 'components/ui/ButtonCircle';
import Img from 'components/ui/Img';
import EntryLink from 'components/ui/Link/EntryLink';
import Portal from 'components/ui/Portal';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';

import styles from './MarkerOverlay.module.scss';

const titleOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="h3"
                    baseTheme="headingXLarge"
                    className={styles.title}
                >
                    {children}
                </Text>
            );
        },
    },
};

const copyOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text as="p" baseTheme="bodySmall" className={styles.copy}>
                    {children}
                </Text>
            );
        },
    },
};

const cropMap = Object.freeze({
    Vegetables: 'vegetables',
    Dairy: 'dairy',
    Greenhouse: 'greenhouse',
    'Fruits / Nuts': 'fruit-nuts',
    Livestock: 'livestock',
    Flowers: 'flowers',
    'Field Crops': 'field-crops',
});

const MarkerOverlay = ({
    isOpen = false,
    crops = [],
    farmName,
    image,
    location,
    markerName,
    markerCopy,
    link,
    onClose = () => {},
}) => {
    return (
        <Portal
            selector="#__secondary_portal__"
            className={cx(styles.portal, isOpen && styles.openPortal)}
        >
            <div className={cx(styles.overlayRoot, isOpen && styles.isVisible)}>
                <div className={styles.overlayInner}>
                    <figure className={styles.markerContent}>
                        <div className={styles.markerContentInner}>
                            <Button
                                onClick={onClose}
                                className={styles.backButton}
                                theme="none"
                            >
                                <div className={styles.backButtonWrapper}>
                                    <ButtonCircle
                                        theme="small-fill-creamdark"
                                        iconType="chevronLeft"
                                    />
                                    <Text as="p" baseTheme="labelLargeAlt">
                                        Back
                                    </Text>
                                </div>
                                <img
                                    className={styles.largeCloseIcon}
                                    src="/images/campaigns/farmers/overlay-close.svg"
                                    alt=""
                                />
                            </Button>
                            <RichText
                                richText={markerName}
                                overrides={titleOverrides}
                            />
                            <div className={styles.locationInfo}>
                                <Text as="p" baseTheme="labelSmall">
                                    {farmName}
                                </Text>
                                <Text
                                    as="span"
                                    baseTheme="labelSmall"
                                    className={styles.divider}
                                >
                                    &#124;
                                </Text>
                                <Text as="p" baseTheme="labelSmall">
                                    {location}
                                </Text>
                            </div>
                            <div className={styles.aspectBox}>
                                {image?.url && (
                                    <Img
                                        className={styles.image}
                                        src={image.url}
                                        fallbackImageWidth={312 * 1.5}
                                    />
                                )}
                            </div>
                            <RichText
                                richText={markerCopy}
                                overrides={copyOverrides}
                            />
                            {link?.__typename === 'AllForFarmersStory' && (
                                <EntryLink entry={link}>
                                    <Button
                                        className={styles.detailButton}
                                        theme="none"
                                    >
                                        <div
                                            className={
                                                styles.detailButtonWrapper
                                            }
                                        >
                                            <Text as="p" baseTheme="bodyMedium">
                                                View Story
                                            </Text>
                                            <ButtonCircle
                                                theme="small-fill-blue"
                                                iconType="chevronRight"
                                            />
                                        </div>
                                    </Button>
                                </EntryLink>
                            )}
                            {link?.__typename === 'ExternalLink' && (
                                <EntryLink entry={link}>
                                    <Button
                                        className={styles.detailButton}
                                        theme="none"
                                    >
                                        <div
                                            className={
                                                styles.detailButtonWrapper
                                            }
                                        >
                                            <Text as="p" baseTheme="bodyMedium">
                                                Learn More
                                            </Text>

                                            <ButtonCircle
                                                theme="small-fill-blue"
                                                iconType="chevronRight"
                                            />
                                        </div>
                                    </Button>
                                </EntryLink>
                            )}
                            <ul className={styles.crops}>
                                <li>
                                    <Text
                                        as="p"
                                        baseTheme="labelSmall"
                                        className={styles.cropLabel}
                                    >
                                        Crops
                                    </Text>
                                </li>
                                {crops?.map((crop, idx) => {
                                    if (cropMap[crop]) {
                                        return (
                                            <li
                                                key={`${crop}-${idx}`}
                                                className={styles.crop}
                                            >
                                                <img
                                                    src={`/images/campaigns/farmers/legend/${cropMap[crop]}.svg`}
                                                    alt=""
                                                />
                                            </li>
                                        );
                                    }

                                    return null;
                                })}
                            </ul>
                        </div>
                    </figure>
                </div>
            </div>
        </Portal>
    );
};

MarkerOverlay.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    image: PropTypes.shape({
        url: PropTypes.string,
    }).isRequired,
    markerCopy: PropTypes.object.isRequired,
    farmName: PropTypes.string.isRequired,
    markerName: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    crops: PropTypes.array,
    link: PropTypes.object,
    slug: PropTypes.string.isRequired,
};

export default MarkerOverlay;
