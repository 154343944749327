import React, { useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from 'components/ui/Button';
import ButtonCircle from 'components/ui/ButtonCircle';
import Img from 'components/ui/Img';
import Portal from 'components/ui/Portal';
import Overlay from 'components/ui/Portal/Overlay';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import SvgIcon from 'components/ui/SvgIcon';
import Video from 'components/ui/Video';

import styles from './VideoPlayer.module.scss';

const headlineImageSrc =
    '//images.ctfassets.net/j8tkpy1gjhi5/3pEPBIo27Ge6BRiLOkQGuU/623e671ecca88ca5fc6cdc6a58236c74/hero_headline.png';
const headlineImageLargeSrc =
    '//images.ctfassets.net/j8tkpy1gjhi5/7vADJPk3kXfpZ5XmV7TPO4/760b7acc44d43a3a58b10c923c4f798c/hero_headline_desktop.png';

const VideoPlayerYT = ({
    className,
    headline,
    youtubeVideoId,
    image,
    imageLarge,
    videoTextureMp4,
    videoTextureWebm,
    videoTexturePoster,
    embeddedEntry = false,
    heroVideo = false,
    isActive = true,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [renderIframe, setRenderIframe] = useState(true);

    const handlePlay = () => {
        if (!isActive) {
            return;
        }
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);

        // solution to get around using YT API
        // when opened: iframe is already rendered so there is no iframe popping
        // when closed: iframe is temporarily erased so video does not continue to play
        setRenderIframe(false);
        Promise.all([new Promise(resolve => setTimeout(resolve, 0))]).then(
            () => {
                setRenderIframe(true);
            }
        );
    };

    return (
        <>
            <div
                onClick={handlePlay}
                className={cx(styles.videoPlayerYT, className, {
                    [styles.isOpen]: isOpen,
                    [styles.embeddedEntry]: embeddedEntry,
                    [styles.heroVideo]: heroVideo,
                    [styles.isActive]: isActive,
                })}
            >
                <h1 className="sr-only">Keep Farmers Farming</h1>
                <div className={styles.background}>
                    {videoTextureMp4?.file?.url &&
                    videoTextureWebm?.file?.url ? (
                        <Video
                            className={styles.image}
                            playState={isOpen ? 'pause' : 'play'}
                            poster={videoTexturePoster?.file?.url}
                            threshold={1}
                            sources={[
                                videoTextureWebm.file.url,
                                videoTextureMp4.file.url,
                            ]}
                        />
                    ) : image?.file?.url && imageLarge?.file?.url ? (
                        <Img
                            className={styles.image}
                            src={image.file.url}
                            fallbackImageWidth={732}
                            alt={image.description || ''}
                            customSources={[
                                {
                                    breakpoint: 768,
                                    src: imageLarge.file.url,
                                    imageWidth: 1420,
                                },
                                {
                                    src: image.file.url,
                                    imageWidth: 732,
                                },
                            ]}
                        />
                    ) : null}
                </div>
                <Stagger className={styles.foreground} threshold={0.2}>
                    <div className={styles.foregroundContentWrapper}>
                        <StaggerChild order={0} className={styles.iconWrapper}>
                            <img
                                src="/images/campaigns/farmers/hero_logo.svg"
                                className={styles.icon}
                                alt=""
                            />
                        </StaggerChild>
                        <StaggerChild
                            order={1}
                            className={styles.headlineOuterWrapper}
                        >
                            <div className={styles.headlineWrapper}>
                                <div className={styles.headlineWrapperInner}>
                                    <Img
                                        className={styles.headlineImage}
                                        src={headlineImageSrc}
                                        fallbackImageWidth={768}
                                        customSources={[
                                            {
                                                breakpoint: 1920,
                                                src: headlineImageLargeSrc,
                                                imageWidth: 1920,
                                            },
                                            {
                                                breakpoint: 1440,
                                                src: headlineImageLargeSrc,
                                                imageWidth: 1440,
                                            },
                                            {
                                                breakpoint: 1024,
                                                src: headlineImageLargeSrc,
                                                imageWidth: 1024,
                                            },
                                            {
                                                breakpoint: 768,
                                                src: headlineImageLargeSrc,
                                                imageWidth: 768,
                                            },
                                            {
                                                src: headlineImageSrc,
                                                imageWidth: 767,
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </StaggerChild>
                        <StaggerChild
                            order={2}
                            className={styles.descriptionWrapper}
                        >
                            <p className={styles.description}>
                                The All For Farmers Coalition is here to help.
                            </p>
                        </StaggerChild>
                        <StaggerChild order={3} className={styles.playVideo}>
                            <ButtonCircle
                                theme="large-thick-cream"
                                iconType="playVideo"
                            >
                                <div className="sr-only">
                                    Open Video Overlay - {headline}
                                </div>
                            </ButtonCircle>
                        </StaggerChild>
                    </div>
                </Stagger>
            </div>
            <Portal>
                <Overlay isOpen={isOpen} handleClose={handleClose}>
                    <div
                        className={cx(styles.videoOverlay, {
                            [styles.isOpen]: isOpen,
                        })}
                    >
                        <div
                            className={styles.videoOverlayBackdrop}
                            onClick={handleClose}
                            role="presentation"
                        />
                        <div
                            className={styles.videoOverlayClose}
                            onClick={handleClose}
                        >
                            <Button
                                className={styles.videoOverlayCloseIcon}
                                theme="close"
                                onClick={handleClose}
                            >
                                <SvgIcon type="close" />
                                <span className="sr-only">
                                    Close Video Overlay - {headline}
                                </span>
                            </Button>
                        </div>
                        <div className={styles.videoOverlayInnerWrap}>
                            <div
                                className={cx(
                                    styles.videoOverlayBackdrop,
                                    styles.transparent
                                )}
                                onClick={handleClose}
                                role="presentation"
                            />
                            <div className={styles.videoOverlayInner}>
                                {renderIframe && (
                                    <iframe
                                        className={styles.iFrame}
                                        id="ytplayer"
                                        type="text/html"
                                        width="640"
                                        height="360"
                                        src={`https://www.youtube.com/embed/${youtubeVideoId}?&origin=https://www.tillamook.com/`}
                                        frameBorder="0"
                                        allowFullScreen
                                    ></iframe>
                                )}
                            </div>
                        </div>
                    </div>
                </Overlay>
            </Portal>
        </>
    );
};

VideoPlayerYT.propTypes = {
    className: PropTypes.string,
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    youtubeVideoId: PropTypes.string,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    videoTextureMp4: PropTypes.object,
    videoTextureWebm: PropTypes.object,
    videoTexturePoster: PropTypes.object,
    embeddedEntry: PropTypes.bool,
    heroVideo: PropTypes.bool,
    isActive: PropTypes.bool,
};

export default VideoPlayerYT;
