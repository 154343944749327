'use client';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';
import { useMedia } from 'react-use';

import cx from 'classnames';
import 'mapbox-gl/dist/mapbox-gl.css';
import PropTypes from 'prop-types';

import Button from 'components/ui/Button';
import ButtonCTA from 'components/ui/ButtonCTA';
import Img from 'components/ui/Img';
import Portal from 'components/ui/Portal';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import MarkerOverlay from './MarkerOverlay';

import styles from './Map.module.scss';

const cropMap = Object.freeze({
    Vegetables: 'vegetables',
    Greenhouse: 'greenhouse',
    Flowers: 'flowers',
    Dairy: 'dairy',
    Livestock: 'livestock',
    'Field Crops': 'field-crops',
    'Fruits / Nuts': 'fruit-nuts',
});

const headlineImageSrc =
    '//images.ctfassets.net/j8tkpy1gjhi5/6NjGQrVHbZq8VGR1sSp9HQ/6274e4d90db7b48e4129ed72c25b75b8/farmers_map_headine.png';
const headlineImageLargeSrc =
    '//images.ctfassets.net/j8tkpy1gjhi5/2BzZdZTW7PDg9GA9JvH92T/f2cfb218a7b1d70ba464488c31b9a713/farmers_map_headline_desktop.png';

const Map = ({ markers = [] }) => {
    const mapRef = useRef(null);

    const isLargeQuery = useMedia('(min-width: 1024px)');
    const [isLarge, setIsLarge] = useState(isLargeQuery);
    const [isOpen, toggleOpen] = useState(false);

    const [selectedMarker, toggleSelectedMarker] = useState(null);
    const [hoveredMarker, toggleHoveredMarker] = useState(null);

    const initialViewState = {
        latitude: 38.473744,
        longitude: -97.186107,
        zoom: isLarge ? 4 : 2,
        bearing: 0,
        pitch: 0,
    };

    useEffect(() => {
        setIsLarge(isLargeQuery);
    }, [isLargeQuery]);

    const handleMarkerClick = marker => {
        if (!selectedMarker || selectedMarker !== marker) {
            toggleSelectedMarker(marker);
        }
    };

    useEffect(() => {
        if (selectedMarker) {
            mapRef.current?.flyTo({
                center: [
                    selectedMarker?.coordinates?.lon,
                    selectedMarker?.coordinates?.lat,
                ],
                duration: 750,
                zoom: 7,
            });
        } else {
        }
    }, [selectedMarker]);

    const openMap = () => {
        toggleOpen(true);
    };

    const closeMarkerOverlay = () => {
        toggleSelectedMarker(null);
        if (isLarge) {
            mapRef.current?.flyTo({
                center: [initialViewState.longitude, initialViewState.latitude],
                duration: 750,
                zoom: initialViewState.zoom,
            });
        }
    };

    const closeMap = () => {
        toggleOpen(false);

        if (selectedMarker) {
            toggleSelectedMarker(null);
        }
    };

    const handleMouseEnter = mark => {
        toggleHoveredMarker(mark);
    };

    const handleMouseLeave = () => {
        toggleHoveredMarker(null);
    };

    const mapMarkers = useMemo(
        () =>
            markers
                .sort((a, b) => b?.coordinates?.lat - a?.coordinates?.lat) // sort by latitude to fix icon layer order
                .map((mark, i) => (
                    <Marker
                        key={`${mark.sys.id}-marker`}
                        longitude={mark?.coordinates?.lon}
                        latitude={mark?.coordinates?.lat}
                        style={{
                            zIndex:
                                hoveredMarker?.sys.id === mark.sys.id
                                    ? '1000'
                                    : '1',
                        }}
                    >
                        <button
                            onClick={() => handleMarkerClick(mark)}
                            className={cx(styles.markerButton, {
                                [styles.isOpen]:
                                    hoveredMarker?.sys.id === mark.sys.id,
                                [styles.isSelectedMarker]:
                                    selectedMarker?.sys.id === mark.sys.id,
                            })}
                            gtm-label="map-icon"
                            style={{
                                '--drop-delay': `${
                                    (markers.length - i) * 100 + 500
                                }ms`,
                            }}
                        >
                            <div
                                className={cx(
                                    styles.marker,
                                    mark.isFeatured && styles.isFeatured
                                )}
                                onMouseEnter={() => handleMouseEnter(mark)}
                                onMouseLeave={handleMouseLeave}
                            >
                                {(mark.isFeatured ||
                                    cropMap[mark.markerIcon]) && (
                                    <div className={styles.cropIconWrapper}>
                                        <img
                                            className={styles.cropIcon}
                                            src={`/images/campaigns/farmers/${
                                                mark.isFeatured
                                                    ? 'featured-star'
                                                    : cropMap[mark.markerIcon]
                                            }.svg`}
                                            alt=""
                                        />
                                    </div>
                                )}
                                <div
                                    className={cx(
                                        styles.featuredMarker,
                                        mark.isFeatured && styles.isActive
                                    )}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <div
                                        className={styles.featuredImageWrapper}
                                    >
                                        {mark.markerImage?.url && (
                                            <Img
                                                className={styles.featuredImage}
                                                src={mark.markerImage.url}
                                                fallbackImageWidth={145 * 2}
                                            />
                                        )}
                                    </div>
                                    <div className={styles.featuredLabelBtn}>
                                        <div className={styles.featuredLabel}>
                                            <Text
                                                as="p"
                                                baseTheme="labelMedium"
                                                className={
                                                    styles.featuredLabelName
                                                }
                                            >
                                                {mark.name}
                                            </Text>
                                            <div
                                                className={
                                                    styles.featuredLabelIcon
                                                }
                                            >
                                                <SvgIcon type="chevronRight" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </Marker>
                )),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [markers, hoveredMarker]
    );

    const legendCrops = useMemo(
        () =>
            Object.keys(cropMap).map(key => (
                <li className={styles.legendCrop} key={`legend-${key}`}>
                    <img
                        className={styles.legendCropIcon}
                        src={`/images/campaigns/farmers/legend/${cropMap[key]}.svg`}
                        alt=""
                    />
                    <Text
                        baseTheme="bodyXSmall"
                        className={styles.legendCropLabel}
                        as="p"
                    >
                        {key}
                    </Text>
                </li>
            )),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [cropMap]
    );

    return (
        <div className={styles.root}>
            <div className={styles.reveal}>
                <div className={styles.revealAspect}>
                    <div className={styles.revealAspectInner}>
                        <div className={styles.contentContainer}>
                            <div className={styles.revealContentInner}>
                                <img
                                    className={styles.revealIcon}
                                    src={
                                        '/images/campaigns/farmers/map-reveal-icon.svg'
                                    }
                                    alt=""
                                />
                                <div className={styles.headlineImageBoxOuter}>
                                    <div
                                        className={styles.headlineImageBoxInner}
                                    >
                                        <Img
                                            className={styles.headlineImage}
                                            src={headlineImageSrc}
                                            fallbackImageWidth={768}
                                            customSources={[
                                                {
                                                    breakpoint: 1920,
                                                    src: headlineImageLargeSrc,
                                                    imageWidth: 1920,
                                                },
                                                {
                                                    breakpoint: 1440,
                                                    src: headlineImageLargeSrc,
                                                    imageWidth: 1440,
                                                },
                                                {
                                                    breakpoint: 1024,
                                                    src: headlineImageLargeSrc,
                                                    imageWidth: 1024,
                                                },
                                                {
                                                    breakpoint: 768,
                                                    src: headlineImageLargeSrc,
                                                    imageWidth: 768,
                                                },
                                                {
                                                    src: headlineImageSrc,
                                                    imageWidth: 767,
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                                <Text
                                    as="p"
                                    baseTheme="bodyMedium"
                                    themes={{ large: 'bodyLarge' }}
                                    className={styles.revealCopy}
                                >
                                    Explore a few farmer stories and how your
                                    help is impacting the future of farming.
                                </Text>
                                <ButtonCTA
                                    text="Explore"
                                    style="filled-cream-white"
                                    iconType="explore"
                                    gtm-label="open-map"
                                    onClick={openMap}
                                />
                            </div>
                        </div>
                        <div className={styles.container}></div>
                    </div>
                </div>
            </div>
            {isOpen && (
                <Portal
                    className={cx(styles.portal, isOpen && styles.openPortal)}
                >
                    <div
                        className={cx(
                            styles.overlayRoot,
                            isOpen && styles.isVisible
                        )}
                    >
                        <div className={styles.overlayInner}>
                            <Button
                                onClick={closeMap}
                                className={styles.closeButton}
                                gtm-label="close-map"
                                theme="none"
                            >
                                <img
                                    src="/images/campaigns/farmers/map-close.svg"
                                    alt=""
                                />
                            </Button>
                            <div className={styles.mapTools}>
                                <div className={styles.navigationControls}>
                                    <Button
                                        theme="none"
                                        className={styles.navControl}
                                        onClick={() => mapRef.current?.zoomIn()}
                                    >
                                        <img
                                            src="/images/campaigns/farmers/plus.svg"
                                            alt=""
                                        />
                                    </Button>
                                    <Button
                                        theme="none"
                                        className={styles.navControl}
                                        onClick={() =>
                                            mapRef.current?.zoomOut()
                                        }
                                    >
                                        <img
                                            src="/images/campaigns/farmers/minus.svg"
                                            alt=""
                                        />
                                    </Button>
                                </div>
                                <ul className={styles.legend}>{legendCrops}</ul>
                            </div>
                            <div className={styles.mapContainer}>
                                <ReactMapGL
                                    ref={mapRef}
                                    className={styles.map}
                                    initialViewState={initialViewState}
                                    style={{
                                        position: 'relative',
                                        overflow: 'hidden',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    mapStyle={
                                        'mapbox://styles/hausla/ckr3xbtp30ebo17qqw3glw7r3'
                                    }
                                    mapboxAccessToken={
                                        'pk.eyJ1IjoiaGF1c2xhIiwiYSI6ImNrbHN0dHRzNjA1d24yd25xZWhka2xmMm8ifQ.C3nu0o6XmETb877Vj6tjfQ'
                                    }
                                >
                                    {mapMarkers}
                                </ReactMapGL>
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
            {selectedMarker && (
                <MarkerOverlay
                    isOpen={selectedMarker?.sys.id}
                    onClose={closeMarkerOverlay}
                    {...selectedMarker}
                />
            )}
        </div>
    );
};

Map.propTypes = {
    markers: PropTypes.array,
};

export default Map;
