'use client';

import React from 'react';

import { ics } from 'calendar-link';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Link from 'components/ui/Link';
import EntryLink from 'components/ui/Link/EntryLink';

import styles from './Button.module.scss';

const Button = ({
    as,
    className,
    children,
    disabled,
    entry,
    href,
    onClick,
    ...props
}) => {
    onClick ??= () => {};

    const addToCalendar = () => {
        const calEvent = ics(entry);

        if (calEvent) {
            fetch(calEvent)
                .then(resp => resp.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    // the filename you want
                    a.download = 'tillamook-cal-event.ics';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(() =>
                    console.warn('An issue occurred with the ics download.')
                );
        }
    };

    const containsCalendarEvent = entry?.__typename === 'LinkEvent';

    const containsLink = (entry || href) && !containsCalendarEvent;

    const Tag = as || containsLink ? 'span' : 'button';

    const renderShell = () => {
        const shellProps = {
            'gtm-id': props['gtm-id'],
            'gtm-label': props['gtm-label'],
            'data-button-id': props['data-button-id'],
            tabIndex: props['tabIndex'],
            disabled: disabled,
            className: cx(styles.buttonResets, styles.base, {
                [className]:
                    className && ((!href && !entry) || containsCalendarEvent),
                [styles.disabled]: disabled,
            }),
        };

        return (
            <Tag
                {...shellProps}
                onClick={containsCalendarEvent ? addToCalendar : onClick}
                {...props}
            >
                {children}
            </Tag>
        );
    };

    const LinkComponent = entry ? EntryLink || Link : Link;

    return containsLink ? (
        <LinkComponent
            {...props}
            href={href}
            entry={entry}
            className={cx(styles.isLink, className && className, {
                [styles.disabled]: disabled,
            })}
        >
            {renderShell()}
        </LinkComponent>
    ) : (
        renderShell()
    );
};

Button.propTypes = {
    as: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    entry: PropTypes.object,
    href: PropTypes.string,
    onClick: PropTypes.func,
    theme: PropTypes.oneOf(['', 'none', 'close']),
    'gtm-id': PropTypes.string,
    'gtm-label': PropTypes.string,
    'data-button-id': PropTypes.string,
    tabIndex: PropTypes.number,
};

export default Button;
