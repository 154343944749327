'use client';

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Img from 'components/ui/Img';
import ReCaptchaLegal from 'components/ui/ReCaptchaLegal';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';

import useSubscribe from 'hooks/useSubscribe';
import { EMAIL_REGEX } from 'utils';
import { blockRootProps } from 'utils';

import CheesePull from './CheesePull';

import styles from './BlockNewsletter.module.scss';

const emailPlaceholder = 'Enter your email';
const emailErrorMessage = 'Email is invalid';
const emailSuccessMessage = 'Subscribed!';

const headlineOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    className={styles.headline}
                    baseTheme="displayXSmall"
                    themes={{
                        large: 'displaySmall',
                        xLarge: 'displayMedium',
                    }}
                >
                    {children}
                </Text>
            );
        },
    },
};

const copyOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    className={styles.copy}
                    baseTheme="bodySmall"
                    themes={{
                        medium: 'bodyXSmall',
                        large: 'bodySmall',
                        xLarge: 'bodyMedium',
                    }}
                >
                    {children}
                </Text>
            );
        },
    },
};

const BlockNewsletter = ({
    __typename,
    anchorId,
    klaviyoSource,
    headline,
    copy,
    image,
    imageOnLeft,
    flushTop,
    flushBottom,
}) => {
    klaviyoSource ??= 'Newsletter';

    const { register, handleSubmit, reset } = useForm({});

    const { isError, isSuccess, isSubmitting, subscribe } = useSubscribe(
        klaviyoSource,
        false
    );

    useEffect(() => {
        if (isSuccess) {
            reset({ email_address: null });
        }
    }, [isSuccess, reset]);

    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.root, {
                [styles.imageOnLeft]: imageOnLeft,
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles.error]: isError,
            })}
        >
            <div className={styles.newsLetterInner}>
                <div className={styles.columns}>
                    <div className={styles.column}>
                        <div className={styles.imageWrap}>
                            {!image?.url && <CheesePull />}
                            {image?.url && (
                                <Img
                                    className={styles.image}
                                    src={image?.url}
                                    fallbackImageWidth={768}
                                    alt={image?.description || ''}
                                    customSources={[
                                        {
                                            breakpoint: 1024,
                                            src: image.url,
                                            imageWidth: 1024 * 1.5,
                                        },
                                        {
                                            breakpoint: 768,
                                            src: image.url,
                                            imageWidth: 768 * 1.5,
                                        },
                                        {
                                            src: image.url,
                                            imageWidth: 767 * 1.5,
                                        },
                                    ]}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.column}>
                        <div className={styles.lockup}>
                            {headline?.json && (
                                <RichText
                                    richText={headline}
                                    overrides={headlineOverrides}
                                />
                            )}
                            {!headline?.json && (
                                <Text
                                    className={styles.headline}
                                    baseTheme="displayXSmall"
                                    themes={{
                                        large: 'displaySmall',
                                        xLarge: 'displayMedium',
                                    }}
                                >
                                    Hungry for
                                    <br />
                                    more?
                                </Text>
                            )}
                            {copy?.json && (
                                <RichText
                                    richText={copy}
                                    overrides={copyOverrides}
                                />
                            )}
                            {!copy?.json && (
                                <Text
                                    className={styles.copy}
                                    baseTheme="bodySmall"
                                    themes={{
                                        medium: 'bodyXSmall',
                                        large: 'bodySmall',
                                        xLarge: 'bodyMedium',
                                    }}
                                >
                                    Our newsletter keeps you in the loop on all
                                    of our special releases, discounts, best
                                    recipes and limited time offerings.
                                </Text>
                            )}
                            <form
                                className={styles.form}
                                onSubmit={handleSubmit(subscribe)}
                            >
                                <div className={styles.inputLockupWrap}>
                                    <div
                                        className={cx(styles.inputLockup, {
                                            [styles.inputSuccess]: isSuccess,
                                        })}
                                    >
                                        <Text
                                            className={styles.inputWrap}
                                            baseTheme="bodyMedium"
                                        >
                                            <input
                                                {...register('email_address', {
                                                    required: true,
                                                    pattern: EMAIL_REGEX,
                                                })}
                                                className={styles.input}
                                                type="email"
                                                placeholder={
                                                    isSuccess
                                                        ? emailSuccessMessage
                                                        : emailPlaceholder
                                                }
                                            />
                                        </Text>
                                    </div>
                                    <ButtonCTA
                                        className={styles.submitButton}
                                        text="Subscribe"
                                        style="filled-blue-ocean"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <div className={styles.legal}>
                                        <ReCaptchaLegal />
                                    </div>
                                    {isError && (
                                        <div className={styles.joinError}>
                                            <Text
                                                className={
                                                    styles.joinErrorInner
                                                }
                                                baseTheme="bodyXSmall"
                                            >
                                                {emailErrorMessage}
                                            </Text>
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

BlockNewsletter.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    klaviyoSource: PropTypes.string,
    headline: PropTypes.object,
    copy: PropTypes.object,
    image: PropTypes.object,
    imageOnLeft: PropTypes.string,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    block: PropTypes.string,
    className: PropTypes.string,
};

export default BlockNewsletter;
