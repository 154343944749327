import React, { useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Img from 'components/ui/Img';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import Text from 'components/ui/Text';

import styles from './ImageWithText.module.scss';

const ImageWithText = ({
    imageSrc,
    imageLargeSrc,
    eyebrow,
    headlineImageSrc,
    headlineImageLargeSrc,
    flushTop = false,
    flushBottom = false,
    theme = 'light',
    hash,
    children = null,
    cta,
}) => {
    const containerRef = useRef(null);
    const [isInView, setIsInView] = useState(false);

    const intersection = useIntersection(containerRef, {
        root: null,
        rootMargin: '40px 0px 40px 0px',
        threshold: 0,
    });

    useEffect(() => {
        if (intersection?.isIntersecting) {
            if (!isInView) {
                setIsInView(true);
            }
        } else {
            if (isInView) {
                setIsInView(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intersection]);

    useEffect(() => {
        intersection && intersection.isIntersecting ? setIsInView(true) : null;
    }, [intersection]);

    if (imageSrc && imageLargeSrc) {
        return (
            <div
                ref={containerRef}
                id={hash}
                className={cx(styles.root, styles[theme], {
                    [styles.flushTop]: flushTop,
                    [styles.flushBottom]: flushBottom,
                })}
            >
                <div className={styles.imageBoxOuter}>
                    <div className={styles.imageBoxInner}>
                        <Img
                            className={styles.image}
                            src={imageSrc}
                            fallbackImageWidth={768}
                            customSources={[
                                {
                                    breakpoint: 1920,
                                    src: imageLargeSrc,
                                    imageWidth: 1920 * 1.5,
                                },
                                {
                                    breakpoint: 1440,
                                    src: imageLargeSrc,
                                    imageWidth: 1440 * 1.5,
                                },
                                {
                                    breakpoint: 1024,
                                    src: imageLargeSrc,
                                    imageWidth: 1024 * 1.5,
                                },
                                {
                                    breakpoint: 768,
                                    src: imageLargeSrc,
                                    imageWidth: 768 * 1.5,
                                },
                                {
                                    src: imageSrc,
                                    imageWidth: 767 * 1.5,
                                },
                            ]}
                        />
                    </div>
                    <Stagger className={styles.contentBox}>
                        <div className={styles.contentBoxInner}>
                            <StaggerChild
                                order={1}
                                as="h5"
                                className={styles.eyebrow}
                            >
                                {eyebrow}
                            </StaggerChild>
                            <div className={styles.headlineImageBoxOuter}>
                                <StaggerChild
                                    order={2}
                                    className={styles.headlineImageBoxInner}
                                >
                                    <Img
                                        className={styles.headlineImage}
                                        src={headlineImageSrc}
                                        fallbackImageWidth={768}
                                        customSources={[
                                            {
                                                breakpoint: 1920,
                                                src: headlineImageLargeSrc,
                                                imageWidth: 1920,
                                            },
                                            {
                                                breakpoint: 1440,
                                                src: headlineImageLargeSrc,
                                                imageWidth: 1440,
                                            },
                                            {
                                                breakpoint: 1024,
                                                src: headlineImageLargeSrc,
                                                imageWidth: 1024,
                                            },
                                            {
                                                breakpoint: 768,
                                                src: headlineImageLargeSrc,
                                                imageWidth: 768,
                                            },
                                            {
                                                src: headlineImageSrc,
                                                imageWidth: 767,
                                            },
                                        ]}
                                    />
                                </StaggerChild>
                            </div>
                            <StaggerChild order={3}>
                                <Text
                                    as="p"
                                    baseTheme="bodyMedium"
                                    themes={{
                                        large: 'headingMedium',
                                    }}
                                    className={styles.copy}
                                    fixWidows
                                >
                                    {children}
                                </Text>
                            </StaggerChild>
                            {cta && (
                                <StaggerChild order={4}>
                                    <ButtonCTA
                                        className={styles.cta}
                                        text={cta.text}
                                        style="filled-blue-cream"
                                        link={{
                                            url: cta.href,
                                        }}
                                        onClick={cta.onOpen ? cta.onOpen : null}
                                    />
                                </StaggerChild>
                            )}
                        </div>
                    </Stagger>
                </div>
            </div>
        );
    }

    return null;
};

ImageWithText.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    imageLargeSrc: PropTypes.string.isRequired,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    eyebrow: PropTypes.string.isRequired,
    headlineImageSrc: PropTypes.string.isRequired,
    headlineImageLargeSrc: PropTypes.string.isRequired,
    theme: PropTypes.string,
    hash: PropTypes.string.isRequired,
    children: PropTypes.node,
    cta: PropTypes.object,
};

export default ImageWithText;
