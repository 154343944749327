import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { blockRootProps } from 'utils';

import BlockNewsletter from '../BlockNewsletter/BlockNewsletter';

import styles from './BlockGlobal.module.scss';

const BlockGlobal = ({ __typename, anchorId, flushTop, block, className }) => {
    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.blockGlobal, className)}
        >
            <BlockNewsletter
                flushTop={flushTop}
                imageOnLeft={block === 'Newsletter Image On Left'}
            />
        </section>
    );
};

BlockGlobal.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    flushTop: PropTypes.bool,
    block: PropTypes.string,
    className: PropTypes.string,
};

export default BlockGlobal;
