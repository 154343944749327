import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ButtonCircle from 'components/ui/ButtonCircle';
import Link from 'components/ui/Link';
import Text from 'components/ui/Text';

import Eyebrow from '../Eyebrow';

import styles from './TripleCards.module.scss';

const cards = [
    {
        title: 'Auction closed',
        description:
            'Learn about our auction where 100% of proceeds go to farmers',
        icon: 'auction',
        hash: 'auction',
    },
    {
        title: 'Learn More',
        description: 'Meet the coalition and learn about barriers farmers face',
        icon: 'educated',
        hash: 'learn',
    },
    {
        title: 'Donate Directly',
        description: '100% of your donation will go to farmers',
        icon: 'donate',
        hash: 'donate',
    },
];

const TripleCards = ({
    headline,
    description,
    flushTop = false,
    flushBottom = false,
}) => {
    return (
        <div
            className={cx(styles.root, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            {headline && description && (
                <Eyebrow headline={headline} flushBottom={true} flushTop={true}>
                    <span>{description}</span>
                </Eyebrow>
            )}
            <ul className={styles.cards}>
                {cards?.map((card, idx) => (
                    <li className={styles.card} key={idx}>
                        <Link href={`#${card.hash}`}>
                            <div className={styles.cardBox}>
                                <div className={styles.cardBoxInner}>
                                    <img
                                        className={styles.icon}
                                        src={`/images/campaigns/farmers/card-${card.icon}.svg`}
                                        alt=""
                                    />
                                    <h4 className={styles.title}>
                                        {card.title}
                                    </h4>
                                    <Text
                                        as="p"
                                        className={styles.description}
                                        baseTheme="bodyMedium"
                                        themes={{
                                            medium: 'bodyLarge',
                                            xxLarge: 'headingMedium',
                                        }}
                                    >
                                        {card.description}
                                    </Text>
                                    <ButtonCircle
                                        className={styles.btn}
                                        theme="small-fill-blue"
                                        iconType="chevronDown"
                                    />
                                </div>
                            </div>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

TripleCards.propTypes = {
    headline: PropTypes.string.isRequired,
    description: PropTypes.node.isRequired,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
};

export default TripleCards;
