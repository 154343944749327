import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import Text from 'components/ui/Text';

import styles from './Eyebrow.module.scss';

const Eyebrow = ({
    headline,
    children = null,
    flushTop = false,
    flushBottom = false,
}) => {
    return (
        <Stagger
            className={cx(styles.root, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            <StaggerChild order={1} as="h3" className={styles.headline}>
                {headline}
            </StaggerChild>
            {children && (
                <StaggerChild order={2} as="p" className={styles.description}>
                    <Text
                        baseTheme="bodyLarge"
                        themes={{ large: 'headingMedium' }}
                        fixWidows
                    >
                        {children}
                    </Text>
                </StaggerChild>
            )}
        </Stagger>
    );
};

Eyebrow.propTypes = {
    headline: PropTypes.string.isRequired,
    children: PropTypes.node,
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
};

export default Eyebrow;
