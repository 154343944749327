'use client';

import React, { useEffect, useRef } from 'react';
import { useIntersection } from 'react-use';

import PropTypes from 'prop-types';

import { getFileExtension } from 'utils';

const defaultProps = {
    controls: true,
};

const texturalProps = {
    autoPlay: true,
    muted: true,
    playsInline: true,
    controls: false,
    loop: true,
    preload: 'auto',
    disablePictureInPicture: true,
};

const Video = ({
    sources,
    textural = true,
    poster = null,
    playState = 'loadstart',
    threshold = 0.25,
    ...props
}) => {
    const videoRef = useRef(null);

    const intersection = useIntersection(videoRef, {
        root: null,
        rootMargin: '0px',
        threshold,
    });

    function playVideo() {
        try {
            videoRef.current?.play();
        } catch (e) {
            console.warn(e);
        }
    }

    function pauseVideo() {
        try {
            videoRef.current?.pause();
        } catch (e) {
            console.warn(e);
        }
    }

    useEffect(() => {
        const intersecting = intersection && intersection.isIntersecting;
        intersecting === true && playVideo();
        intersecting === false && pauseVideo();
    }, [intersection, playState]);

    useEffect(() => {
        playState === 'play' && playVideo();
        playState === 'pause' && pauseVideo();
    }, [playState]);

    const videoProps = Object.assign(
        textural ? texturalProps : defaultProps,
        props
    );

    return (
        <video
            {...videoProps}
            poster={poster ? `${poster}?w=1200&fm=webp&q=80` : null}
            ref={videoRef}
        >
            {sources.map((source, i) => {
                const type = getFileExtension(source);
                return (
                    <source
                        key={i}
                        src={source}
                        type={`video/${type}`}
                    ></source>
                );
            })}
        </video>
    );
};

Video.propTypes = {
    playState: PropTypes.string,
    poster: PropTypes.string,
    sources: PropTypes.array.isRequired,
    textural: PropTypes.bool,
    threshold: PropTypes.number,
};

export default Video;
