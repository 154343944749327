import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useMount } from 'react-use';

import {
    clearAllBodyScrollLocks,
    disableBodyScroll,
    enableBodyScroll,
} from 'body-scroll-lock';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Portal.module.scss';

const Overlay = ({ children, isOpen, handleClose }) => {
    const overlayRef = useRef(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleKeyDown = event => {
        if (event.keyCode === 27) {
            handleClose();
        }
    };

    useLayoutEffect(() => {
        if (isOpen === null) return;

        isOpen
            ? (disableBodyScroll(
                  overlayRef.current,
                  {
                      allowTouchMove: el => {
                          while (el && el !== document.body) {
                              if (
                                  el.getAttribute('body-scroll-lock-ignore') !==
                                  null
                              ) {
                                  return true;
                              }

                              el = el.parentElement;
                          }
                      },
                  },
                  [isOpen]
              ),
              document.body.style.setProperty(
                  'top',
                  `${window.scrollY * -1}px`
              )) // fix for scroll-to-top body-scroll-lock bug: https://github.com/willmcpo/body-scroll-lock/issues/237#issuecomment-954804479
            : enableBodyScroll(overlayRef.current);

        return function beforeUnmount() {
            clearAllBodyScrollLocks();
        };
    }, [isOpen]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false);
        return () => {
            document.removeEventListener('keydown', handleKeyDown, false);
        };
    }, [handleKeyDown]);

    return (
        <div
            ref={overlayRef}
            className={cx(styles.overlay, {
                [styles.isOpen]: isOpen,
            })}
        >
            {children}
        </div>
    );
};

Overlay.propTypes = {
    children: PropTypes.node,
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func,
};

const OverlayMounted = props => {
    const [mounted, setMounted] = useState(false);
    useMount(() => setMounted(true));

    return mounted ? <Overlay {...props} /> : null;
};

export default OverlayMounted;
