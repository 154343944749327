import React, { useEffect, useRef, useState } from 'react';

import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import cx from 'classnames';
import IframeResizer from 'iframe-resizer-react';
import { usePathname } from 'next/navigation';
import PropTypes from 'prop-types';

import Button from 'components/ui/Button';
import Portal from 'components/ui/Portal';

import styles from './Bug.module.scss';

const Bug = ({ isOpen, onClose = () => {}, onOpen = () => {}, isVisible }) => {
    const overlayRef = useRef(null);
    const [isOverlayVisible, toggleVisibility] = useState(false);
    const pathname = usePathname();
    function wait(ms) {
        return new Promise(resolve => {
            setTimeout(resolve, ms);
        });
    }

    function waitFrame() {
        return new Promise(resolve => {
            requestAnimationFrame(resolve);
        });
    }

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('keydown', handleKeyDown);

            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
        isOpen && handleBodyScroll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    function handleBodyScroll() {
        if (overlayRef.current) {
            disableBodyScroll(overlayRef.current);

            // Transition the overlay on
            toggleVisibility(true);

            return;
        }

        // gentle pause to ensure overlay ref exists
        wait(10).then(() => {
            handleBodyScroll();
        });
    }

    function handleClose() {
        toggleVisibility(false);
        clearAllBodyScrollLocks();

        waitFrame().then(() => {
            onClose();
        });
    }

    useEffect(() => {
        clearAllBodyScrollLocks();
    }, [pathname]);

    const openPortal = () => {
        onOpen();
    };

    const handleKeyDown = evt => {
        if (evt.keyCode === 27) {
            handleClose();
        }
    };

    return (
        <Button
            onClick={openPortal}
            theme="none"
            className={cx(styles.root, { [styles.isVisible]: isVisible })}
        >
            <div className={styles.innerBug}>
                <img
                    src={'/images/campaigns/farmers/bug-text.svg'}
                    className={styles.bugText}
                    alt=""
                />
                <div className={styles.bugHeartWrapper}>
                    <img
                        src={'/images/campaigns/farmers/bug-heart.svg'}
                        className={styles.bugHeart}
                        alt=""
                    />
                </div>
            </div>
            {isOpen && (
                <Portal
                    className={cx(styles.portal, isOpen && styles.openPortal)}
                >
                    <div
                        ref={overlayRef}
                        className={cx(
                            styles.overlayRoot,
                            isOverlayVisible && styles.isOverlayVisible
                        )}
                    >
                        <Button
                            onClick={handleClose}
                            className={styles.closeButton}
                            theme="none"
                        >
                            <img
                                src="/images/campaigns/farmers/map-close.svg"
                                alt=""
                            />
                        </Button>
                        <div className={styles.overlayInner}>
                            <IframeResizer
                                autoResize={true}
                                frameBorder={0}
                                className={styles.iframe}
                                seamless
                                allowpaymentrequest
                                scrolling="yes"
                                src="https://givebutter.com/embed/c/AllForFarmers"
                            />
                        </div>
                    </div>
                </Portal>
            )}
        </Button>
    );
};

Bug.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    isVisible: PropTypes.bool,
};

export default Bug;
