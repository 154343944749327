'use client';

import React, { useState } from 'react';

import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import BlockGlobal from 'components/blocks/BlockGlobal/BlockGlobal';
import BlockWhereToBuy from 'components/blocks/BlockWhereToBuy/BlockWhereToBuy';
import Link from 'components/ui/Link';
import EntryLink from 'components/ui/Link/EntryLink';

import Bug from './Bug';
import Hero from './Hero';
//import ScrollingInfographic from './ScrollingInfographic';
import ImageWithText from './ImageWithText';
import Map from './Map';
import TripleCards from './TripleCards';
import TwoColumnText from './TwoColumnText';

// import DryCleaners from './DryCleaners';
// import Eyebrow from './Eyebrow';
import styles from './AllForFarmers.module.scss';

const ImpactCalculator = dynamic(() => import('./ImpactCalculator'), {
    ssr: false,
});

// const asset = url => {
//     return {
//         file: { url },
//     };
// };

// const featuredCards = [
//     {
//         image: {
//             file: {
//                 url: '//images.ctfassets.net/j8tkpy1gjhi5/7v9fErjLJDUoV3N95MOkoh/1010ee468f82693cf758579e387168dc/farmers_news_01.png',
//                 details: {
//                     size: 108916,
//                     image: {
//                         width: 816,
//                         height: 1070,
//                     },
//                 },
//                 contentType: 'image/jpeg',
//             },
//         },
//         id: '64rmjdZWvkCEbNd0o08234',
//         contentTypeId: 'cardLatest',
//     },
//     {
//         contentTypeId: 'cardLatest',
//         image: {
//             title: 'packaging-card',
//             file: {
//                 url: '//images.ctfassets.net/j8tkpy1gjhi5/1dxamxXuMJLfuI6dHZzgQ4/a0b32a7461f7bfdb61fd439f575b96e1/farmers_news_09.png',
//                 details: {
//                     size: 615360,
//                     image: {
//                         width: 816,
//                         height: 1070,
//                     },
//                 },
//                 fileName: 'packaging-card.jpg',
//                 contentType: 'image/jpeg',
//             },
//         },
//         cta: {
//             contentTypeId: 'cta',
//             link: {
//                 slug: 'stewardship/thriving-farms',
//             },
//         },
//     },
//     {
//         contentTypeId: 'cardLatest',
//         image: {
//             title: 'packaging-card',
//             file: {
//                 url: '//images.ctfassets.net/j8tkpy1gjhi5/6xNBXyUIj27OLaHqoRIrJJ/298708218ea3c3cb4ed9513da6751db1/farmers_news_05.png',
//                 details: {
//                     size: 615360,
//                     image: {
//                         width: 816,
//                         height: 1070,
//                     },
//                 },
//                 fileName: 'packaging-card.jpg',
//                 contentType: 'image/jpeg',
//             },
//         },
//         cta: {
//             contentTypeId: 'cta',
//             link: {
//                 contentTypeId: 'allForFarmersStory',
//                 slug: 'farming-in-a-pandemic',
//             },
//         },
//     },
//     {
//         contentTypeId: 'cardLatest',
//         image: {
//             title: 'packaging-card',
//             file: {
//                 url: '//images.ctfassets.net/j8tkpy1gjhi5/4O6To39VbYsFehY1OVU5ef/4e64e3f42ca2261cb672b56760be55fc/rise_and_root_farm.png',
//                 details: {
//                     size: 615360,
//                     image: {
//                         width: 816,
//                         height: 1070,
//                     },
//                 },
//                 fileName: 'packaging-card.jpg',
//                 contentType: 'image/jpeg',
//             },
//         },
//         cta: {
//             contentTypeId: 'cta',
//             link: {
//                 contentTypeId: 'allForFarmersStory',
//                 slug: 'rise-and-root-farm',
//             },
//         },
//     },
//     {
//         contentTypeId: 'cardLatest',
//         image: {
//             title: 'packaging-card',
//             file: {
//                 url: '//images.ctfassets.net/j8tkpy1gjhi5/3ndWEiCc7WMar5m7htk7P0/ce6d6baa6fb7323e945b2bc41195ac63/farmers_news_02.png',
//                 details: {
//                     size: 615360,
//                     image: {
//                         width: 816,
//                         height: 1070,
//                     },
//                 },
//                 fileName: 'packaging-card.jpg',
//                 contentType: 'image/jpeg',
//             },
//         },
//         cta: {
//             contentTypeId: 'cta',
//             link: {
//                 contentTypeId: 'allForFarmersStory',
//                 slug: 'the-savvy-side-of-farming',
//             },
//         },
//     },
//     {
//         contentTypeId: 'cardLatest',
//         image: {
//             title: 'packaging-card',
//             file: {
//                 url: '//images.ctfassets.net/j8tkpy1gjhi5/Tdq4Shw7VDaHZLrk6t5hG/b33ef3ada2a5104f9f529ac000c6f8e5/aloha_redland_farms.png',
//                 details: {
//                     size: 615360,
//                     image: {
//                         width: 816,
//                         height: 1070,
//                     },
//                 },
//                 fileName: 'packaging-card.jpg',
//                 contentType: 'image/jpeg',
//             },
//         },
//         cta: {
//             contentTypeId: 'cta',
//             link: {
//                 contentTypeId: 'allForFarmersStory',
//                 slug: 'aloha-redland-farm',
//             },
//         },
//     },
//     {
//         contentTypeId: 'cardLatest',
//         image: {
//             title: 'packaging-card',
//             file: {
//                 url: '//images.ctfassets.net/j8tkpy1gjhi5/2vdPxfcsLaVizNWsKRkPRC/ce1cbc80f21e2ab51321bdad9f0ab8b1/farmers_news_03.png',
//                 details: {
//                     size: 615360,
//                     image: {
//                         width: 816,
//                         height: 1070,
//                     },
//                 },
//                 fileName: 'packaging-card.jpg',
//                 contentType: 'image/jpeg',
//             },
//         },
//         cta: {
//             contentTypeId: 'cta',
//             link: {
//                 contentTypeId: 'allForFarmersStory',
//                 slug: 'disappearing-farmland',
//             },
//         },
//     },
//     {
//         contentTypeId: 'cardLatest',
//         image: {
//             title: 'packaging-card',
//             file: {
//                 url: '//images.ctfassets.net/j8tkpy1gjhi5/1QKFRaRkVv2jk81hkSbzpt/564e01ced67c752d4a83cea0f08e1cb6/farmers_news_04.png',
//                 details: {
//                     size: 615360,
//                     image: {
//                         width: 816,
//                         height: 1070,
//                     },
//                 },
//                 fileName: 'packaging-card.jpg',
//                 contentType: 'image/jpeg',
//             },
//         },
//         cta: {
//             contentTypeId: 'cta',
//             link: {
//                 contentTypeId: 'allForFarmersStory',
//                 slug: 'future-of-farming',
//             },
//         },
//     },
//     {
//         contentTypeId: 'cardLatest',
//         image: {
//             title: 'packaging-card',
//             file: {
//                 url: '//images.ctfassets.net/j8tkpy1gjhi5/74qKB2iMDvoOZBkHQMQWK6/b240c9fb9bac35e91e4ffe6cd849dbec/farmers_news_08.png',
//                 details: {
//                     size: 615360,
//                     image: {
//                         width: 816,
//                         height: 1070,
//                     },
//                 },
//                 fileName: 'packaging-card.jpg',
//                 contentType: 'image/jpeg',
//             },
//         },
//         cta: {
//             contentTypeId: 'cta',
//             link: {
//                 contentTypeId: 'news',
//                 slug: 'tillamook-joins-the-global-b-corp-movement',
//             },
//         },
//     },
// ];

// const carouselSlides = [
//     {
//         contentTypeId: 'carouselShowcaseWithCardSlide',
//         eyebrow: 'A Food52 Exclusive',
//         headline: {
//             data: {},
//             content: [
//                 {
//                     data: {},
//                     content: [
//                         {
//                             data: {},
//                             marks: [],
//                             value: 'Give a gift\nthat gives back',
//                             nodeType: 'text',
//                         },
//                     ],
//                     nodeType: 'paragraph',
//                 },
//             ],
//             nodeType: 'document',
//         },
//         image: {
//             title: 'mobile-1',
//             file: {
//                 url: '//images.ctfassets.net/j8tkpy1gjhi5/6jL9t9gbGveTc1UuACOdQb/2154e94ad43f1e78e3b215760b8c56d6/farmers_showcase_slide_1.jpg',
//                 details: {
//                     image: {
//                         width: 750,
//                         height: 940,
//                     },
//                 },
//                 fileName: 'mobile-1.jpg',
//                 contentType: 'image/jpeg',
//             },
//         },
//         imageLarge: {
//             title: 'Desktop-1',
//             file: {
//                 url: '//images.ctfassets.net/j8tkpy1gjhi5/7KiyzR6AU05fDJvumSJwgE/8d0b9cc2104fdbbfa8d9608a7c36ac44/farmers_showcase_slide_1_desktop.jpg',
//                 details: {
//                     image: {
//                         width: 2880,
//                         height: 1500,
//                     },
//                 },
//                 fileName: 'Desktop-1.jpg',
//                 contentType: 'image/jpeg',
//             },
//         },
//         bodyCopy: {
//             data: {},
//             content: [
//                 {
//                     data: {},
//                     content: [
//                         {
//                             data: {},
//                             marks: [],
//                             value: 'This holiday season, when you give gifts from the All For Farmers Market on Food52, you’re part of a coalition of food lovers helping farmers keep doing what they love.',
//                             nodeType: 'text',
//                         },
//                     ],
//                     nodeType: 'paragraph',
//                 },
//             ],
//             nodeType: 'document',
//         },
//         cta: {
//             contentTypeId: 'cta',
//             link: {
//                 contentTypeId: 'externalLink',
//                 url: 'https://www.food52.com/all-for-farmers-market',
//             },
//             text: 'Shop Now',
//         },
//     },
//     {
//         contentTypeId: 'carouselShowcaseWithCardSlide',
//         eyebrow: 'A Food52 Exclusive',
//         headline: {
//             data: {},
//             content: [
//                 {
//                     data: {},
//                     content: [
//                         {
//                             data: {},
//                             marks: [],
//                             value: 'For the Love of\nCheese and Farmers',
//                             nodeType: 'text',
//                         },
//                     ],
//                     nodeType: 'paragraph',
//                 },
//             ],
//             nodeType: 'document',
//         },
//         image: {
//             title: 'mobile-1',
//             file: {
//                 url: '//images.ctfassets.net/j8tkpy1gjhi5/62Vo4PKCFOtlUKIeMsdrS9/175073056d09afc89307c3582cb2fede/farmers_carousel_slide_2.jpg',
//                 details: {
//                     size: 1185211,
//                     image: {
//                         width: 750,
//                         height: 940,
//                     },
//                 },
//                 fileName: 'mobile-1.jpg',
//                 contentType: 'image/jpeg',
//             },
//         },
//         imageLarge: {
//             title: 'Desktop-1',
//             file: {
//                 url: '//images.ctfassets.net/j8tkpy1gjhi5/14CQ1x1Voal1SIWcE5We0G/05605e19d98e5380c34805daf76ffbcb/farmers_carousel_slide_2_desktop.jpg',
//                 details: {
//                     size: 5913233,
//                     image: {
//                         width: 2880,
//                         height: 1500,
//                     },
//                 },
//                 fileName: 'Desktop-1.jpg',
//                 contentType: 'image/jpeg',
//             },
//         },
//         bodyCopy: {
//             data: {},
//             content: [
//                 {
//                     data: {},
//                     content: [
//                         {
//                             data: {},
//                             marks: [],
//                             value: 'Every cheesy bite you take matters, because when you buy Tillamook Cheese, you’re backing our farmer-values and supporting our efforts to protect farms and farmers.',
//                             nodeType: 'text',
//                         },
//                     ],
//                     nodeType: 'paragraph',
//                 },
//             ],
//             nodeType: 'document',
//         },
//         cta: {
//             contentTypeId: 'cta',
//             link: {
//                 contentTypeId: 'externalLink',
//                 url: 'https://www.food52.com/all-for-farmers-market',
//             },
//             text: 'Shop Now',
//         },
//     },
// ];

const AllForFarmers = ({ markers }) => {
    const [donateOverlayIsOpen, toggleDonateOverlay] = useState(false);

    const openDonateOverlay = () => {
        toggleDonateOverlay(true);
    };

    const closeDonateOverlay = () => {
        toggleDonateOverlay(false);
    };

    return (
        <div className={styles.root}>
            <div className={styles.bugContainer}>
                <Hero />
                <TripleCards
                    headline={
                        'The food you love comes from farmers who need your help'
                    }
                    description={
                        'As a farmer-owned co-op, we understand that farmers today continue to face numerous challenges. That’s why we’re back with year three of All For Farmers to support farmers across the nation. This year we’re hosting the All For Farmers Auction where 100% of proceeds go to farmers in need. Join us by taking action to support.'
                    }
                />
                <ImageWithText
                    hash="auction"
                    flushBottom
                    imageSrc={
                        '//images.ctfassets.net/j8tkpy1gjhi5/43hMLwIsUWRe2SWKnxhYhw/3d751f2ec8269c31d2d56bd34d1eba43/supportafarmer-mobile.jpg'
                    }
                    imageLargeSrc={
                        '//images.ctfassets.net/j8tkpy1gjhi5/7p9KFmIfeAfxI4iDDPmSU6/ddd3fc5e674bc18b7c97d40f944e5872/supportafarmer-desktop.jpg'
                    }
                    eyebrow={'Bidding closed'}
                    headlineImageSrc={
                        '//images.ctfassets.net/j8tkpy1gjhi5/72HnoUQjgd0Vx2hMwl75VX/98cae78467fcaea28b43d7a644723ec4/supportafarmeroneitematatime_mobile.png'
                    }
                    headlineImageLargeSrc={
                        '//images.ctfassets.net/j8tkpy1gjhi5/7ftrG2wvG2M4rumAKgQSvk/411578212132f6ba362c649e5b7ae7b8/tyforsupporting-headline_1.png'
                    }
                >
                    <span>
                        We created the <strong>All For Farmers Auction</strong>{' '}
                        to support farmers in need. The auction is now closed,
                        but thanks to you we raised over $55,000 that will
                        directly benefit farmers and help keep them farming for
                        generations to come.
                    </span>
                </ImageWithText>
                {/* <DryCleaners /> */}
                <ImageWithText
                    flushTop={true}
                    hash="learn"
                    imageSrc={
                        '//images.ctfassets.net/j8tkpy1gjhi5/2qZmHBEzyub4IW0Grx1Eqp/0116335d29edeefb7539963b6d083835/farmers-a-brighter-future-mobile.jpg'
                    }
                    imageLargeSrc={
                        '//images.ctfassets.net/j8tkpy1gjhi5/2WphA63wInsa3XVuc4gsgs/4ea1ac76a865e06a2f8f6a7aa86f4d4d/farmers-a-brighter-future-desktop.jpg'
                    }
                    eyebrow={'Learn More'}
                    headlineImageSrc={
                        '//images.ctfassets.net/j8tkpy1gjhi5/lqBJSC7Bn2KNpIt4V5XYx/6bad7b22d08d7749254c1a034f1c4d70/brighter-future.png'
                    }
                    headlineImageLargeSrc={
                        '//images.ctfassets.net/j8tkpy1gjhi5/4VOO5JAAhFBq1xttctc8dD/b457079c06b69ca453edad5e6bfad1b5/brighter-future-large.png'
                    }
                    flushBottom={true}
                    theme={'dark'}
                >
                    <span>
                        <EntryLink
                            entry={{ slug: 'stewardship/thriving-farms' }}
                        >
                            We do right by farmers, always have, always will.
                        </EntryLink>{' '}
                        Since last year, YOU helped us raise over $740K for the
                        <Link href="https://farmland.org/brighter-future/">
                            Brighter Future Fund.
                        </Link>{' '}
                        Those funds and all those raised through the All For
                        Farmers Coalition will have an immediate impact on
                        farmers.
                    </span>
                </ImageWithText>
                {/* <section className={styles.fullWidthWrapper}>
                    <Block
                        flushTop={true}
                        contentTypeId={'blockVideoCarousel'}
                        videos={[
                            {
                                headline: 'Aloha Redland Farms',
                                eyebrow: "Farmers' Stories",
                                youtubeVideoId: 'gOztaNoGkmo',
                                videoTextureMp4: asset(
                                    '//videos.ctfassets.net/j8tkpy1gjhi5/48xECyqeQq5y6DJzAH4Df6/44d1ee5e292551147e1b021d90536c63/farmers_videocarousel_aloha.mp4'
                                ),
                                videoTextureWebm: asset(
                                    '//videos.ctfassets.net/j8tkpy1gjhi5/ds9FIJCfiRl1hnbCYHNJc/6a28a3cdc5805af3a9b013d69d14312c/farmers_videocarousel_aloha.webm'
                                ),
                                videoTexturePoster: asset(
                                    '//images.ctfassets.net/j8tkpy1gjhi5/1FZvSoPZ9JwVYcvWutmjht/b71f7c74754731c0e8345c2f16ee38ff/farmers_videocarousel_aloha.jpg'
                                ),
                                id: '2dge12WPVFh1fUUUGFyOnU',
                                contentTypeId: 'videoPlayerYoutube',
                            },
                            {
                                headline: 'Rise & Root Farm',
                                eyebrow: "Farmers' Stories",
                                youtubeVideoId: 'L26NTFqWlfQ',
                                videoTextureMp4: asset(
                                    '//videos.ctfassets.net/j8tkpy1gjhi5/1kOlKORI0MEhneIjBvlXWt/70da1c744ab159b2b8cf39e9b0e0a356/farmers_videocarousel_riseroot.mp4'
                                ),
                                videoTextureWebm: asset(
                                    '//videos.ctfassets.net/j8tkpy1gjhi5/3Hkr8kyCti8Ce7RGksgJAg/8b9da6962f55182c7186c5f59e0a542e/farmers_videocarousel_riseroot.webm'
                                ),
                                videoTexturePoster: asset(
                                    '//images.ctfassets.net/j8tkpy1gjhi5/7E6G3onccBdd9OWgrtCane/ad17702e168d6c4542300a08509ac12a/farmers_videocarousel_riseroot.jpg'
                                ),
                                id: '2dge12WPVFh1fUUUGFyOnU',
                                contentTypeId: 'videoPlayerYoutube',
                            },
                        ]}
                    />
                    <Block
                        contentTypeId={'blockCardGroup'}
                        cards={featuredCards}
                        flushTop={true}
                        flushBottom={true}
                        theme={'Default'}
                    />
                </section> */}
                {/* <span id="map">
                    <Eyebrow headline={'Meet The Farms'} flushBottom={true}>
                        <span>
                            Explore the map to meet some of our grant
                            recipients.
                        </span>
                    </Eyebrow>
                </span> */}
                <Map markers={markers} />
                {/* <ScrollingInfographic /> */}

                <ImageWithText
                    flushTop={true}
                    hash="donate"
                    imageSrc={
                        '//images.ctfassets.net/j8tkpy1gjhi5/6crynOTOcL5mtESwsnMBa8/28f10dd850ccb077630380ee07c735cd/farmers_for-the-future-mobile.jpg'
                    }
                    imageLargeSrc={
                        '//images.ctfassets.net/j8tkpy1gjhi5/7mqnIO2VF2zMTfwePjVUdp/643eba2d63dedc0f7c789114ace0f814/farmers_for-the-future-desktop.jpg'
                    }
                    eyebrow={'Donate Directly'}
                    headlineImageSrc={
                        '//images.ctfassets.net/j8tkpy1gjhi5/4kmJf4Xctp5EtTXGPzWjzJ/3ff136e5f8d0f6ed8567ac046d20184b/farmers_future_headline_mobile.png'
                    }
                    headlineImageLargeSrc={
                        '//images.ctfassets.net/j8tkpy1gjhi5/2rqoFqSFEiNSVkjTzjWnIh/1d19e200ff74c3fb83b01bc12a242c10/farmers_future_headline_desktop.png'
                    }
                    cta={{ onOpen: openDonateOverlay, text: 'Donate Now' }}
                >
                    <span>
                        A small change for you could mean significant change to
                        a farmer. That’s why the All For Farmers Coalition makes
                        it easy to support farmers through everyday actions—like
                        buying Tillamook products, shopping select items on
                        Food52 or simply donating to American Farmland Trust.
                    </span>
                </ImageWithText>
                <TwoColumnText flushTop={true} flushBottom={true} />
                <ImpactCalculator onOpen={openDonateOverlay} />
                <section className={styles.fullWidthWrapper}>
                    <BlockGlobal flushTop={true} block={'Newsletter'} />
                    <BlockWhereToBuy bodyCopy="Every time you buy Tillamook products, you’re helping us to support the future of farming for generations to come. Find what you need in stores near you." />
                </section>
            </div>

            <Bug
                isOpen={donateOverlayIsOpen}
                onClose={closeDonateOverlay}
                onOpen={openDonateOverlay}
                isVisible={true}
            />
        </div>
    );
};

AllForFarmers.propTypes = {
    markers: PropTypes.array,
};

export default AllForFarmers;
