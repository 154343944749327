'use client';

import * as React from 'react';

import { clamp, mapRange } from '@madeinhaus/utils';
import PropTypes from 'prop-types';

const useScrollProgress = ({
    callback,
    distance = 200,
    windowHeightBehavior = 'subtract',
}) => {
    const [section, setSection] = React.useState(null);
    const [node, setNode] = React.useState(null);
    const [dimensions, setDimensions] = React.useState(null);
    const [windowHeight, setWindowHeight] = React.useState(0);

    const heightMap = React.useMemo(
        () => ({
            add: windowHeight,
            subtract: -windowHeight,
            ignore: 0,
        }),
        [windowHeight]
    );

    React.useEffect(() => {
        const onResize = () => {
            setWindowHeight(window.outerHeight);

            if (section) {
                const { top, height } = section.getBoundingClientRect();
                const documentTop = window.scrollY + top;
                setDimensions({ top: documentTop, height });
            }
        };

        onResize();
        window.addEventListener('resize', onResize, false, { passive: true });

        return () => {
            window.removeEventListener('resize', onResize, false);
        };
    }, [section]);

    const handleScroll = React.useCallback(() => {
        if (
            !section ||
            !dimensions?.height ||
            typeof dimensions?.top !== 'number'
        ) {
            return;
        }
        const scroll = window.scrollY;
        const { top, height } = dimensions;
        const start = top + heightMap[windowHeightBehavior];
        const end = top + height;
        const progress = clamp(mapRange(scroll, start, end, 0, 1), 0, 1);
        if (callback) {
            callback(progress);
        } else {
            (node ?? section).style.setProperty(
                'transform',
                `translate3d(0, ${progress * distance}px, 0)`
            );
        }
    }, [
        callback,
        dimensions,
        distance,
        heightMap,
        node,
        section,
        windowHeightBehavior,
    ]);

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        handleScroll(); // initial call

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [
        callback,
        dimensions,
        distance,
        handleScroll,
        heightMap,
        node,
        section,
        windowHeightBehavior,
    ]);

    return { setSection, setNode };
};

useScrollProgress.propTypes = {
    callback: PropTypes.func,
    distance: PropTypes.number,
    windowHeightBehavior: PropTypes.oneOf(['add', 'subtract', 'ignore']),
};

export default useScrollProgress;
