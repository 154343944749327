'use client';

import React, { useRef } from 'react';
import { useMedia } from 'react-use';

import { clamp, mapRange } from '@madeinhaus/utils';

import Img from 'components/ui/Img';

import useScrollProgress from 'hooks/useScrollProgress';

import styles from './CheesePull.module.scss';

const scrollOffset = 0.3;

const CheesePull = () => {
    const topRef = useRef(null);
    const cheeseRef = useRef(null);

    const isMedium = useMedia('(min-width: 768px)', false);
    const isLarge = useMedia('(min-width: 1024px)', false);

    let topValue = 55;
    let cheeseValue = 1.9;

    if (isMedium && !isLarge) {
        topValue = 60;
        cheeseValue = 2.2;
    }

    const { setSection } = useScrollProgress({
        windowHeightBehavior: 'subtract',
        callback(progress) {
            // transform progress with offset
            const newProgress = (progress - scrollOffset) / scrollOffset;

            const topMovement = clamp(
                mapRange(newProgress, 0, 1, 0, topValue),
                0,
                topValue
            );

            const cheeseScale = clamp(
                mapRange(newProgress, 0, 1, 1, cheeseValue),
                1,
                cheeseValue
            );

            if (topRef.current && cheeseRef.current) {
                topRef.current.style.transform = `translateY(${-topMovement}%)`;
                cheeseRef.current.style.transform = `scaleY(${cheeseScale})`;
            }
        },
    });

    return (
        <div ref={setSection} className={styles.root} aria-hidden>
            <div ref={cheeseRef} className={styles.cheese}>
                <Img
                    src="/images/newsletter/cheese-cheese.png"
                    alt=""
                    width={983}
                    height={676}
                />
            </div>
            <div className={styles.bottom}>
                <Img
                    src="/images/newsletter/cheese-bottom.png"
                    alt=""
                    width={893}
                    height={345}
                />
            </div>
            <div ref={topRef} className={styles.top}>
                <Img
                    src="/images/newsletter/cheese-top.png"
                    alt=""
                    width={893}
                    height={433}
                />
            </div>
        </div>
    );
};

export default CheesePull;
