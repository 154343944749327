import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import VideoPlayer from './VideoPlayer';

import styles from './Hero.module.scss';

const video = {
    youtubeVideoId: 'L77qXhXtWFc',
    videoTextureMp4: {
        title: 'slices-textural (1)',
        file: {
            url: '//videos.ctfassets.net/j8tkpy1gjhi5/4zzwv2Gavu4VSiUlC0bEXM/55f6b02d5a13961b400b8f5a7783e627/farmers_hero_bg.mp4',
            details: {
                size: 521105,
            },
            fileName: 'slices-textural (1).mp4',
            contentType: 'video/mp4',
        },
    },
    videoTextureWebm: {
        title: 'slices-textural',
        file: {
            url: '//videos.ctfassets.net/j8tkpy1gjhi5/1C2MA59vQF3a29Htnym7kz/e749198b388530484fcad90f639fca06/farmers_hero_bg.webm',
            details: {
                size: 333894,
            },
            fileName: 'slices-textural.webm',
            contentType: 'video/webm',
        },
    },
    videoTexturePoster: {
        title: 'slices-textural',
        file: {
            url: '//images.ctfassets.net/j8tkpy1gjhi5/2tS6jf9SGq6dLWH3rQGCwg/70dc26729d547026bd814337be164a21/farmers_hero_bg.jpg',
            details: {
                size: 987144,
                image: {
                    width: 1920,
                    height: 1080,
                },
            },
            fileName: 'slices-textural.jpg',
            contentType: 'image/jpeg',
        },
    },
    id: '13gYPvySfiVGEB1clcgeKu',
    contentTypeId: 'videoPlayerYoutube',
};

const Hero = ({ flushBottom = false }) => (
    <div
        className={cx(styles.root, {
            [styles.flushBottom]: flushBottom,
        })}
    >
        <div className={styles.inner}>
            <VideoPlayer heroVideo={true} {...video} />
        </div>
    </div>
);

Hero.propTypes = {
    flushBottom: PropTypes.bool,
};

export default Hero;
