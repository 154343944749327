import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Link from 'components/ui/Link';
import Text from 'components/ui/Text';

import { widowKiller } from 'utils';

import styles from './TwoColumnText.module.scss';

const TwoColumnText = ({ flushTop = false, flushBottom = false }) => {
    return (
        <div
            className={cx(styles.root, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            <div className={styles.col}>
                <h3 className={styles.headline}>
                    {widowKiller('See how far your donation will go')}
                </h3>
            </div>
            <div className={styles.col}>
                <Text
                    as="p"
                    baseTheme="bodyLarge"
                    themes={{ large: 'headingMedium' }}
                    className={styles.copy}
                >
                    Donate to{' '}
                    <Link href="https://farmland.org/">
                        American Farmland Trust
                    </Link>
                    , a not-for-profit that protects farmland, promotes sound
                    farming practices and keeps farmers on the land.
                </Text>
            </div>
        </div>
    );
};

TwoColumnText.propTypes = {
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
};

export default TwoColumnText;
